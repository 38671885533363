import styled from "styled-components"

const AwardsSectionWrapper = styled.section`
  padding: 100px 0 100px;
  overflow: hidden;

  @media only screen and (max-width: 990px) {
    padding: 80px 0 80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0 50px;
  }

  .row {
    .gatsby-image-wrapper {
      margin: 0px auto;
      max-width: 200px;
      @media only screen and (max-width: 480px) {
        max-width: 150px;
      }
    }
  }
`

export default AwardsSectionWrapper
