import styled from "styled-components"

const ClutchSectionWrapper = styled.section`
  padding: 100px 0 100px;
  overflow: hidden;

  @media only screen and (max-width: 990px) {
    padding: 80px 0 80px;
  }
  @media only screen and (max-width: 480px) {
    padding: 40px 0 40px;
  }

  .row {
    margin-left: -25px;
    @media only screen and (max-width: 480px) {
      margin-left: 0px;
    }
  }
`

export default ClutchSectionWrapper
