import React from "react"

import Box from "../../components/Box"
import ClutchSectionWrapper from "./clutchReviews.style"
import Container from "../../components/UI/Container"
import TitleSection from "../TitleSection"

const ClutchSection = () => {
  return (
    <ClutchSectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA="Clutch"
          titleB="Reviews"
          subTitleMaxWidth="544px"
        />
        <Box className="row">
          <iframe
            id="iframe-0.08384805284370866"
            width="100%"
            src="https://widget.clutch.co/widgets/get/4?ref_domain=condescending-knuth-2869ec.netlify.app&amp;uid=1112856&amp;ref_path=/reviews"
            height="600px"
            scrolling="no"
            title="[object Object]4"
            frameBorder="0"
          ></iframe>
        </Box>
      </Container>
    </ClutchSectionWrapper>
  )
}

export default ClutchSection
