import AwardsSectionWrapper from "./awards.style"
import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Img from "gatsby-image"
import React from "react"
import TitleSection from "../TitleSection"

const AwardsSection = ({ content }) => {
  const { title, title_colored, image } = content[0]
  return (
    <AwardsSectionWrapper>
      <Container width="1220px">
        <TitleSection
          titleA={title.html}
          titleB={title_colored.html}
          subTitleMaxWidth="544px"
        />
        <Box className="row">
          <Img
            fadeIn={false}
            loading="lazy"
            fluid={image.localFile?.childImageSharp.fluid}
            alt="award"
          />
        </Box>
      </Container>
    </AwardsSectionWrapper>
  )
}

export default AwardsSection
