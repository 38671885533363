import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Awards from "../containers/Awards"
import Banner from "../containers/Banner"
import ClutchReviews from "../containers/ClutchReviews"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import Statistics from "../containers/Statistics"
import Testimonials from "../containers/Testimonials"
import UpworkReviews from "../containers/UpworkReviews"
import { ContentWrapper } from "../containers/alpacked.style"

const ReviewsPage = ({ location }) => {
  const reviewsData = useStaticQuery(graphql`
    query Reviews {
      prismicReviews {
        data {
          seo_title {
            text
          }
          seo_short_description {
            text
          }
          seo_description {
            text
          }
          image_link_preview {
            fluid {
              src
            }
          }
          body {
            ... on PrismicReviewsBodyBanner {
              id
              items {
                title {
                  html
                }
                subtitle
                image {
                  url
                }
              }
            }
            ... on PrismicReviewsBodyStatistics {
              id
              items {
                title {
                  html
                }
                subtitle
              }
            }
            ... on PrismicReviewsBodyTestimonials {
              id
              primary {
                title {
                  html
                }
                title_colored {
                  html
                }
              }
              items {
                name {
                  text
                }
                occupation
                testimonial
                linkedin_url {
                  url
                }
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1400, quality: 72) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicReviewsBodyAward {
              id
              items {
                title {
                  html
                }
                title_colored {
                  html
                }
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1400, quality: 72) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicReviewsBodyUpworkReviews {
              id
              primary {
                title {
                  html
                }
                title_colored {
                  html
                }
              }
              items {
                image {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    body,
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
  } = reviewsData.prismicReviews.data
  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
        loadClutch
      />
      <Layout pathname="/reviews">
        <ContentWrapper>
          <Banner
            title={body[0].items[0].title.html}
            subtitle={body[0].items[0].subtitle}
            mainButtonText={body[0].items[0].button_a_title}
            mainButtonLink={body[0].items[0].button_a_link}
            secButtonText={body[0].items[0].button_b_title}
            secButtonLink={body[0].items[0].button_b_link}
            image={body[0].items[0].image.url}
          />
          <Statistics content={body[1].items} />
          <ClutchReviews />
          <UpworkReviews content={body[2]} />
          <Testimonials content={body[3]} hideButton />
          <Awards content={body[4].items} />
        </ContentWrapper>
        <Consultation />
      </Layout>
    </>
  )
}

export default ReviewsPage
