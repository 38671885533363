import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const UpworkSlideWrapper = styled.div`
  .glide__bullets {
    margin-top: 45px;
    text-align: center;
    @media (max-width: 575px) {
      margin-top: 30px;
    }
    .glide__bullet {
      width: 12px;
      height: 12px;
      background: #e7f1ed;
      margin: 5px;
      transition: 0.15s ease-in-out;
      &:hover {
        background: #d8e2de;
      }
      &.glide__bullet--active {
        background: ${themeGet("colors.mainBlue")};
      }
    }
  }
`

export const UpworkItem = styled.div`
  position: relative;
  display: block;
  background: #fff;
  border: 1px solid #f2f4f7;
  border-radius: 5px;
  padding: 10px;

  @media (max-width: 480px) {
    padding: 5px;
  }
`
