import { UpworkItem, UpworkSlideWrapper } from "./upWorkReviews.style"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import GlideCarousel from "../../components/GlideCarousel"
import GlideSlide from "../../components/GlideCarousel/glideSlide"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import TitleSection from "../TitleSection"

const TestimonialSection = ({ sectionWrapper, content }) => {
  const { items, primary } = content

  const carouselOptions = {
    type: "carousel",
    autoplay: 6000,
    perView: 2,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 1,
      },
    },
  }

  return (
    <Box {...sectionWrapper} as="section" id="testimonial_section">
      <Container width="1220px">
        <TitleSection
          titleA={primary.title.html}
          titleB={primary.title_colored.html}
        />
        <UpworkSlideWrapper>
          <GlideCarousel
            options={carouselOptions}
            carouselSelector="testimonial__slider"
            controls={false}
            bullets={true}
            numberOfBullets={items.length}
          >
            <>
              {items.map((item, index) => (
                <GlideSlide key={`testimonial-slide-${index}`}>
                  <UpworkItem>
                    <Image
                      fadeIn={false}
                      loading="lazy"
                      fluid={item.image.localFile?.childImageSharp.fluid}
                      alt={`reviewer-image-${index}`}
                    />
                  </UpworkItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </UpworkSlideWrapper>
      </Container>
    </Box>
  )
}

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  content: PropTypes.object,
}

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pb: ["40px", "80px", "80px", "120px", "120px"],
  },
}

export default TestimonialSection
