import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const CounterWrapper = styled.div`
  position: relative;
  margin-top: -117px;
  background-color: ${themeGet("colors.mainBlue")};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px 30px;
  @media only screen and (max-width: 990px) {
    padding: 30px 30px 30px;
    flex-direction: column;
    margin-top: -200px;
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 30px 30px;
    flex-direction: column;
    margin-top: -200px;
  }
`

export const CounterItem = styled.div`
  width: 33%;
  text-align: center;
  @media only screen and (max-width: 990px) {
    margin-bottom: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 40px;
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0px;
  }

  h1,
  h2,
  h3 {
    font-size: 40px;
    line-height: 68px;
    font-weight: 700;
    color: ${themeGet("colors.white")};
    @media only screen and (max-width: 990px) {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto 0 auto;
    max-width: 348px;
    color: ${themeGet("colors.white")};
    @media only screen and (max-width: 990px) {
      font-size: 16px;
    }
  }
`
