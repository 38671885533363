import React from "react"
import striptags from "striptags"
import Text from "../../components/Text"
import Heading from "../../components/Heading"
import Container from "../../components/UI/Container"
import { CounterWrapper, CounterItem } from "./statistics.style"

const Statistics = ({ content }) => {
  return (
    <Container width="1220px">
      <CounterWrapper>
        {content.map((item, index) => {
          return (
            <CounterItem key={index}>
              <Heading
                as={item.title.html.substring(1, 3)}
                content={striptags(item.title.html)}
              />
              <Text content={item.subtitle} />
            </CounterItem>
          )
        })}
      </CounterWrapper>
    </Container>
  )
}

export default Statistics
